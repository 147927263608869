import { PlayEvent } from "@u-wave/react-vimeo";
import {
  BannerSection,
  Breadcrumb,
  BreadcrumbItem,
  Loader,
} from "../../shared/components";
import Container from "../../shared/components/Container";
import {
  ComponentObjetoEducacionalInfogaficoInfografico,
  ObjetoEducacionalEntity,
  ResidenciaMedicaEntity,
  useCreateConsumoModuloResidenciaUserMutation,
  useCreateConsumoResidenciaUserMutation,
  useGetResidenciaMedicaByIdLazyQuery,
} from "../../generated/graphql";
import { debounceTime } from "rxjs/operators";
import { Subject } from "rxjs";

import { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../hooks/useGtm";
import useOeConsume from "../../hooks/useOeConsume";
import { UserContext, getUserContext } from "../../context/UserContext";
import { useScrollTop } from "../../shared/hooks/useScrollTop";
import { AboutAuthors } from "../OEPage/components/SobreAutores";
import DescritivoObjeto from "./DescritivoObjeto";
import ApresentacaoConteudo from "./ApresentacaoConteudo";
import PainelResidencia from "./PainelResidencia";
import { useUtils } from "../../shared/utils";
import useVisualizacaoPreparaEinstein from "../../hooks/useVisualizacaoPreparaEinstein";
import { TIPO_PAGINA } from "../../hooks/useBannerApi";
import {
  Banner,
  loadBannerPreparaEinstein,
} from "../../graphql/collections/queries/banner.query";
import BarraFavoritar from "./BarraFavoritar";
import CursosPagosResidencia from "../ResidenciaMedicaHome/components/cursospagos-residencia";
import { useModalCursosPagos } from "../../hooks/useModalCursosPagos";
import { mostrarModalLogin } from "../../shared/modals/ModalLogin";

export default function ResidenciaMedicaTrilha() {
  const params = useParams<{ id: string, moduloId: string, objetoId: string }>();
  const history = useHistory();
  const scrollTop = useScrollTop();

  const gtm = useGtm();
  const _user = getUserContext();
  const { saveOeConsume } = useOeConsume();
  const { saveVizualizacaoResidencia } = useVisualizacaoPreparaEinstein();
  const handleTimeUpdateRef = useRef<any>(null);
  const [expandedModules, setExpandedModules] = useState<number[]>([]);
  const [residenciaMedicas, setResidenciaMedicas] =
    useState<ResidenciaMedicaEntity>();
  const [isMoreModule, setIsMoreModule] = useState(false);
  const [isMoreObjeto, setIsMoreObjeto] = useState(false);

  const [objetoEducacionalSelecionado, setObjetoEducacionalSelecionado] =
    useState<ObjetoEducacionalEntity | null>(null);

  const [objetoEducacionalConcluido, setObjetoEducacionalConcluido] =
    useState<Boolean>(false);

  useEffect(() => {
    if (params.id && params.moduloId && params.objetoId && residenciaMedicas?.attributes) {
      const indexSelecionadoModuloGeral: number = residenciaMedicas?.attributes?.residencia_medica_modulos?.data?.findIndex(
        a => {
          return a.id === params.moduloId.toString()
        }
      )!;

      if (indexSelecionadoModuloGeral === -1) {
        return;
      }

      const isMoreModule =
      indexSelecionadoModuloGeral <
      (residenciaMedicas?.attributes?.residencia_medica_modulos?.data?.length ||
        0) - 1;

      const indexSelecionadoObjGeral =
      indexSelecionadoModuloGeral > -1
        ? (residenciaMedicas?.attributes?.residencia_medica_modulos?.data[
            indexSelecionadoModuloGeral
          ].attributes
            ? residenciaMedicas?.attributes?.residencia_medica_modulos?.data[
                indexSelecionadoModuloGeral
              ].attributes?.objeto_educacionals?.data?.findIndex(
                (oe) => oe?.id === params.objetoId,
              )
            : 0) || 0
          : 0;


      const isMoreObjeto = indexSelecionadoModuloGeral > -1 && residenciaMedicas?.attributes?.residencia_medica_modulos?.data[
        indexSelecionadoModuloGeral
      ] &&
      residenciaMedicas?.attributes?.residencia_medica_modulos?.data[
        indexSelecionadoModuloGeral
      ].attributes && indexSelecionadoObjGeral <
      (residenciaMedicas?.attributes?.residencia_medica_modulos?.data[
        indexSelecionadoModuloGeral
      ].attributes?.objeto_educacionals?.data?.length || 0) - 1 || false;

    setIsMoreModule(isMoreModule);
      setIsMoreObjeto(isMoreObjeto);
      if (indexSelecionadoObjGeral > -1) {
        setObjetoEducacionalSelecionado(residenciaMedicas?.attributes?.residencia_medica_modulos?.data[
            indexSelecionadoModuloGeral
        ].attributes?.objeto_educacionals?.data[
          indexSelecionadoObjGeral
        ]!
        );
      }
      setExpandedModules([indexSelecionadoModuloGeral]);



    }
  }, [params.id, params.moduloId, params.objetoId, residenciaMedicas]);

  const handleSetObjetoSelecionado = (
    objeto: ObjetoEducacionalEntity,
    concluido: boolean,
  ) => {
    scrollTop();
    setObjetoEducacionalSelecionado(objeto);
    setObjetoEducacionalConcluido(concluido);

    const indexSelecionadoModuloGeral = objeto
      ? residenciaMedicas?.attributes?.residencia_medica_modulos?.data?.findIndex(
          (modulo) =>
            modulo?.attributes?.objeto_educacionals?.data?.find(
              (oe) => oe?.id === objeto?.id,
            ),
        ) || 0
      : 0;

    const isMoreModule =
      indexSelecionadoModuloGeral <
      (residenciaMedicas?.attributes?.residencia_medica_modulos?.data?.length ||
        0) -
        1;
    const indexSelecionadoObjGeral =
      indexSelecionadoModuloGeral > -1
        ? (residenciaMedicas?.attributes?.residencia_medica_modulos?.data[
            indexSelecionadoModuloGeral
          ].attributes
            ? residenciaMedicas?.attributes?.residencia_medica_modulos?.data[
                indexSelecionadoModuloGeral
              ].attributes?.objeto_educacionals?.data?.findIndex(
                (oe) => oe?.id === objeto?.id,
              )
            : 0) || 0
        : 0;
    const isMoreObjeto =
      indexSelecionadoObjGeral <
      (residenciaMedicas?.attributes?.residencia_medica_modulos?.data[
        indexSelecionadoModuloGeral
      ].attributes
        ? (residenciaMedicas?.attributes?.residencia_medica_modulos?.data[
            indexSelecionadoModuloGeral
          ].attributes?.objeto_educacionals?.data?.length || 0) - 1
        : 0);

    history.replace(`/prepara-einstein/${residenciaMedicas?.id}/${residenciaMedicas?.attributes?.residencia_medica_modulos?.data[
        indexSelecionadoModuloGeral
      ].id}/${residenciaMedicas?.attributes?.residencia_medica_modulos?.data[
            indexSelecionadoModuloGeral
          ].attributes?.objeto_educacionals?.data[
            indexSelecionadoObjGeral
          ].id}`);
    setIsMoreModule(isMoreModule);
    setIsMoreObjeto(isMoreObjeto);
    setExpandedModules([indexSelecionadoModuloGeral]);
  };

  const [residenciaMedicaById, { loading, refetch }] =
    useGetResidenciaMedicaByIdLazyQuery({
      fetchPolicy: "network-only",
      onCompleted: (response) => {
        const { data } = response.residenciaModuloHomePage!;
        if (data) {
          setResidenciaMedicas(data! as ResidenciaMedicaEntity);
        } else {
          setResidenciaMedicas(data);
        }
      },
    });

  const [createConsumoModuloResidencia, { loading: loadingConsumoModulo }] =
    useCreateConsumoModuloResidenciaUserMutation({
      onCompleted: (response) => {
        const residenciaModuloSelecionado =
          residenciaMedicas?.attributes?.residencia_medica_modulos?.data?.find(
            (modulo) =>
              modulo?.attributes?.objeto_educacionals?.data?.find(
                (oe) => oe?.id === objetoEducacionalSelecionado?.id,
              ),
          );
        const residenciaModulo =
          residenciaMedicas?.attributes?.residenciaMedicaQtdModulo?.find(
            (item) => item?.id == residenciaModuloSelecionado?.id,
          );

        if (
          residenciaModulo?.quantidadeObjetos ===
          residenciaModulo?.progresso! + 1
        ) {
          createConsumoResidenciaUser({
            variables: {
              input: {
                modulo_id: Number(
                  residenciaMedicas?.attributes?.residencia_medica_modulos?.data?.find(
                    (modulo) =>
                      modulo?.attributes?.objeto_educacionals?.data?.find(
                        (oe) => oe?.id === objetoEducacionalSelecionado?.id,
                      ),
                  )?.id!,
                ),
                user_id: Number(_user?.id!),
                residencia_id: Number(params?.id!),
              },
            },
          });
        }

        refetch({
          id: params?.id,
        });
        scrollTop();
        setObjetoEducacionalConcluido(true);
      },
    });

  const [
    createConsumoResidenciaUser,
    { loading: loadingConsumoResidenciaUser },
  ] = useCreateConsumoResidenciaUserMutation({
    onCompleted: (response) => {},
  });

  const { removeAcentosCaracteresInvalidosEspacos } = useUtils();

  useEffect(() => {
    if (residenciaMedicas?.id) {
      gtm.push(
        `preparaeinstein_area_${removeAcentosCaracteresInvalidosEspacos(
          residenciaMedicas?.attributes?.titulo!,
        )}_views` as any,
        EDataLayerEventAction.NAVEGACAO,
        {
          label: residenciaMedicas?.attributes?.titulo!,
        },
      );
    }
  }, [residenciaMedicas?.id]);

  const { user } = useContext(UserContext);

  useEffect(() => {
    const timeUpdateSubject = new Subject();

    // Atribui a função handleTimeUpdate ao useRef
    handleTimeUpdateRef.current = (event: any, objetoEducacional: any) => {
      timeUpdateSubject.next({ event, objetoEducacional });
    };
    // Aplica o debounceTime ao subject
    const debouncedTimeUpdate = timeUpdateSubject.pipe(debounceTime(500));

    // Assina o subject debounced
    const subscription = debouncedTimeUpdate.subscribe((data: any) => {
      // Obtém o progresso atual do vídeo
      const currentDuration = data.event.duration;
      const currentSeconds = data.event.seconds;

      const oeAssistido =
        user?.attributes?.OesConsumidosParcialmente?.data.find(
          (item) =>
            !!item?.attributes?.VideoAudioId &&
            objetoEducacionalSelecionado?.attributes?.VideoAudio &&
            objetoEducacionalSelecionado?.attributes?.VideoAudio.length > 0 &&
            item?.attributes?.VideoAudioId.toString() ===
              objetoEducacionalSelecionado?.attributes?.VideoAudio[0].id,
        );

        if (
          objetoEducacionalSelecionado?.attributes?.VideoAudio &&
          objetoEducacionalSelecionado?.attributes?.VideoAudio.length > 0
        ) {
          localStorage.setItem(
            "UPDATE_VIDEO",
            JSON.stringify({
              id: oeAssistido?.id,
              infograficoId: oeAssistido?.attributes?.ImagemOeId,
              leituraId: oeAssistido?.attributes?.LeituraOeId,
              oe: objetoEducacionalSelecionado.id,
              partialDuration: parseInt(currentSeconds.toString().split(".")[0]),
              totalDuration: parseInt(currentDuration.toString().split(".")[0]),
              user: user?.id,
              videoAudioId: objetoEducacionalSelecionado?.attributes
                ?.VideoAudio[0].id as any,
            }),
          );
        }

        save(currentSeconds, currentDuration, data.objetoEducacional);
    });

    saveVizualizacaoResidencia({
      residencia_id: params.id,
      user_id: _user?.id,
    });

    // Lembre-se de cancelar a inscrição ao desmontar o componente
    return () => {
      subscription.unsubscribe();
      timeUpdateSubject.complete();
    };
  }, []);

  const handleFinish = async (
    ev: any,
    info?: ComponentObjetoEducacionalInfogaficoInfografico,
  ) => {
    const currentDuration = ev?.duration;
    const currentSeconds = ev?.seconds;

    const user = getUserContext();

    if (
      objetoEducacionalConcluido &&
      !objetoEducacionalSelecionado?.attributes?.TipoObjeto?.data.find(
        (t) => t.attributes?.titulo === "Questionário",
      )
    )
      return;

    if (info) {
      await saveOeConsume({
        infograficoId: info ? parseInt(info.id) : undefined,
        partialDuration: 1,
        totalDuration: 1,
        oe: objetoEducacionalSelecionado?.id,
        user: objetoEducacionalSelecionado?.id,
      });
    } else if (currentDuration || currentSeconds) {
      save(currentSeconds, currentDuration, objetoEducacionalSelecionado);
    }

    await createConsumoModuloResidencia({
      variables: {
        input: {
          modulo_id: Number(
            residenciaMedicas?.attributes?.residencia_medica_modulos?.data?.find(
              (modulo) =>
                modulo?.attributes?.objeto_educacionals?.data?.find(
                  (oe) => oe?.id === objetoEducacionalSelecionado?.id,
                ),
            )?.id!,
          ),
          user_id: Number(_user?.id!),
          oe_id: Number(objetoEducacionalSelecionado?.id!),
          residencia_id: Number(params?.id!),
        },
      },
    });
    residenciaMedicaById({
      variables: {
        id: params.id,
      },
    });
  };

  const save = async (
    seconds: number,
    duration: number,
    objetoEducacional: any,
  ) => {
    if (
      !user ||
      !objetoEducacional?.attributes?.VideoAudio ||
      !objetoEducacional?.attributes?.VideoAudio[0]
    )
      return;

    const oeAssistido = user?.attributes?.OesConsumidosParcialmente?.data.find(
      (item: any) =>
        item.attributes?.VideoAudioId &&
        objetoEducacional?.attributes?.VideoAudio &&
        item.attributes?.VideoAudioId!.toString() ===
          objetoEducacional.attributes.VideoAudio[0].id.toString(),
    );

    /** Para nao perder a declaracao caso o usuario ja tenha concluido o consumo desse video */
    if (
      !!oeAssistido &&
      oeAssistido?.attributes?.duracao &&
      oeAssistido?.attributes?.duracao > 0 &&
      oeAssistido?.attributes?.duracao === oeAssistido?.attributes?.segundos
    ) {
      return;
    }

    const videoAudioId = objetoEducacional!.attributes!.VideoAudio[0]?.id || "";
    await saveOeConsume({
      id: oeAssistido?.id,
      videoAudioId: parseInt(videoAudioId),
      user: user?.id,
      partialDuration: parseInt(seconds?.toString().split(".")[0]),
      totalDuration: parseInt(duration?.toString().split(".")[0]),
      oe: objetoEducacional.id,
    });
  };

  useEffect(() => {
    residenciaMedicaById({
      variables: {
        id: params?.id,
      },
    });
  }, [params]);

  const handleModuleToggle = (index: number) => {
    setExpandedModules((prevExpandedModules) => {
      if (prevExpandedModules.includes(index)) {
        return prevExpandedModules.filter((item) => item !== index);
      } else {
        return [index];
      }
    });
  };

  const handlePlayEvent = async (e: PlayEvent) => {
    handleTimeUpdateRef.current(e, objetoEducacionalSelecionado);

    // GTM doc linha 86!!!
    gtm.push(
      EDataLayerEventCategory.ADE_OE_INTERACAO_VIDEO,
      EDataLayerEventAction.CLIQUE,
      {
        label: objetoEducacionalSelecionado?.attributes?.Titulo!,
      },
    );
  };

  const handleNextOe = (
    objetoEducacionalSelecionado: ObjetoEducacionalEntity | null,
  ) => {
    const indexSelecionadoModulo =
      residenciaMedicas?.attributes?.residencia_medica_modulos?.data?.findIndex(
        (modulo) =>
          modulo?.attributes?.objeto_educacionals?.data?.find(
            (oe) => oe?.id === objetoEducacionalSelecionado?.id,
          ),
      ) || 0;
    const indexSelecionadoObj =
      residenciaMedicas?.attributes?.residencia_medica_modulos?.data[
        indexSelecionadoModulo
      ].attributes?.objeto_educacionals?.data.findIndex(
        (oe) => oe?.id === objetoEducacionalSelecionado?.id,
      ) || 0;
    if (
      indexSelecionadoObj <
      (residenciaMedicas?.attributes?.residencia_medica_modulos?.data[
        indexSelecionadoModulo
      ].attributes?.objeto_educacionals?.data.length || 0) -
        1
    ) {
      const objSelecionado =
        residenciaMedicas?.attributes?.residencia_medica_modulos?.data[
          indexSelecionadoModulo
        ].attributes?.objeto_educacionals?.data[indexSelecionadoObj + 1];
      setObjetoEducacionalSelecionado(objSelecionado || null);
      const indexSelecionadoModuloGeral = objSelecionado
        ? residenciaMedicas?.attributes?.residencia_medica_modulos?.data?.findIndex(
            (modulo) =>
              modulo?.attributes?.objeto_educacionals?.data?.find(
                (oe) => oe?.id === objSelecionado?.id,
              ),
          ) || 0
        : 0;

      const isMoreModule =
        indexSelecionadoModuloGeral <
        (residenciaMedicas?.attributes?.residencia_medica_modulos?.data
          ?.length || 0) -
          1;
      const indexSelecionadoObjGeral =
        indexSelecionadoModuloGeral > -1
          ? (residenciaMedicas?.attributes?.residencia_medica_modulos?.data[
              indexSelecionadoModuloGeral
            ].attributes
              ? residenciaMedicas?.attributes?.residencia_medica_modulos?.data[
                  indexSelecionadoModuloGeral
                ].attributes?.objeto_educacionals?.data?.findIndex(
                  (oe) => oe?.id === objSelecionado?.id,
                )
              : 0) || 0
          : 0;
      const isMoreObjeto =
        indexSelecionadoObjGeral <
        (residenciaMedicas?.attributes?.residencia_medica_modulos?.data[
          indexSelecionadoModuloGeral
        ].attributes
          ? (residenciaMedicas?.attributes?.residencia_medica_modulos?.data[
              indexSelecionadoModuloGeral
            ].attributes?.objeto_educacionals?.data?.length || 0) - 1
          : 0);
      setIsMoreModule(isMoreModule);
      setIsMoreObjeto(isMoreObjeto);
    } else if (
      indexSelecionadoModulo <
      (residenciaMedicas?.attributes?.residencia_medica_modulos?.data?.length ||
        0) -
        1
    ) {
      const indexSelecionadoObjNew =
        (residenciaMedicas?.attributes?.residencia_medica_modulos?.data[
          indexSelecionadoModulo + 1
        ].attributes?.objeto_educacionals?.data.length || 0) > 0
          ? 0
          : -1;
      if (indexSelecionadoObj != -1) {
        const objSelecionado =
          residenciaMedicas?.attributes?.residencia_medica_modulos?.data[
            indexSelecionadoModulo + 1
          ].attributes?.objeto_educacionals?.data[indexSelecionadoObjNew + 1];
        setObjetoEducacionalSelecionado(objSelecionado || null);
        const indexSelecionadoModuloGeral = objSelecionado
          ? residenciaMedicas?.attributes?.residencia_medica_modulos?.data?.findIndex(
              (modulo) =>
                modulo?.attributes?.objeto_educacionals?.data?.find(
                  (oe) => oe?.id === objSelecionado?.id,
                ),
            ) || 0
          : 0;

        const isMoreModule =
          indexSelecionadoModuloGeral <
          (residenciaMedicas?.attributes?.residencia_medica_modulos?.data
            ?.length || 0) -
            1;
        const indexSelecionadoObjGeral =
          indexSelecionadoModuloGeral > -1
            ? (residenciaMedicas?.attributes?.residencia_medica_modulos?.data[
                indexSelecionadoModuloGeral
              ].attributes
                ? residenciaMedicas?.attributes?.residencia_medica_modulos?.data[
                    indexSelecionadoModuloGeral
                  ].attributes?.objeto_educacionals?.data?.findIndex(
                    (oe) => oe?.id === objSelecionado?.id,
                  )
                : 0) || 0
            : 0;
        const isMoreObjeto =
          indexSelecionadoObjGeral <
          (residenciaMedicas?.attributes?.residencia_medica_modulos?.data[
            indexSelecionadoModuloGeral
          ].attributes
            ? (residenciaMedicas?.attributes?.residencia_medica_modulos?.data[
                indexSelecionadoModuloGeral
              ].attributes?.objeto_educacionals?.data?.length || 0) - 1
            : 0);
        setIsMoreModule(isMoreModule);
        setIsMoreObjeto(isMoreObjeto);
      }
    }
  };

  const _oeAssistido = _user?.attributes?.OesConsumidosParcialmente?.data.find(
    (item) =>
      !!item?.attributes?.VideoAudioId &&
      objetoEducacionalSelecionado?.attributes?.VideoAudio &&
      objetoEducacionalSelecionado?.attributes?.VideoAudio.length > 0 &&
      item?.attributes?.VideoAudioId.toString() ===
        objetoEducacionalSelecionado?.attributes?.VideoAudio[0].id,
  );

  const ext: any =
    objetoEducacionalSelecionado?.attributes?.Infografico &&
    objetoEducacionalSelecionado?.attributes?.Infografico.length > 0 &&
    objetoEducacionalSelecionado?.attributes?.Infografico[0].Imagem?.data?.attributes?.url.split(
      ".",
    );

  const video =
    objetoEducacionalSelecionado?.attributes?.VideoAudio &&
    objetoEducacionalSelecionado?.attributes?.VideoAudio.length > 0
      ? objetoEducacionalSelecionado?.attributes?.VideoAudio[0]?.URL!
      : "https://vimeo.com/717651585/05bd6f3818";

  const [banners, setBanners] = useState<Banner[]>([]);

  const carregarBanner = async () => {
    const resultBanner = await loadBannerPreparaEinstein({
      homePage: false,
    });

    setBanners(resultBanner);
  };

  useEffect(() => {
    carregarBanner();
  }, []);

  useEffect(() => {
    if (!_user) {
      //history.replace("/prepara-einstein");
      mostrarModalLogin(true, () => {
          history.push("/");
      });
    }
  }, [user])


  return (
    <div className="bg-[#F5F8FF] py-0">
      <Loader
        show={loading && !residenciaMedicas}
        message="Aguarde enquanto o conteúdo é carregado..."
      />
      <Container className="residencia py-6">
        <nav className="mb-2 mt-6 flex" aria-label="Breadcrumb">
          <ol className="inline-flex list-none gap-2 p-0">
            <li className="flex items-center gap-2">
              <a
                href="/"
                className="text-xxs text-[#111316] hover:text-[#11131680] md:text-xs"
              >
                Home
              </a>
              <span className="text-xxs md:text-xs">{" > "} </span>
            </li>
            <li className="flex items-center gap-2">
              <a
                href="/prepara-einstein"
                className="text-xxs text-[#111316] hover:text-[#11131680] md:text-xs"
              >
                Prepara Einstein
              </a>
              <span className="text-xxs md:text-xs">{" > "} </span>
            </li>
            <li className="flex items-center gap-2">
              <a
                href={`/prepara-einstein/${residenciaMedicas?.id}`}
                className="text-xxs text-[#111316] hover:text-[#11131680] md:text-xs"
              >
                {residenciaMedicas?.attributes?.titulo}
              </a>
            </li>
          </ol>
        </nav>
        <h3 className="text-xxl font-semibold leading-normal text-[#004F92]">
          {residenciaMedicas?.attributes?.titulo}
        </h3>
        <div className="!mt-5 flex h-auto w-full flex-col justify-between gap-6 pb-3 md:mt-8 md:flex-row">
          <div className="flex h-auto w-full flex-col">
            <ApresentacaoConteudo
              objetoEducacionalSelecionado={objetoEducacionalSelecionado}
              objetoEducacionalConcluido={objetoEducacionalConcluido}
              video={video}
              handleTimeUpdateRef={handleTimeUpdateRef}
              handleFinish={handleFinish}
              handlePlayEvent={handlePlayEvent}
              loadingConsumoModulo={
                loadingConsumoModulo || loadingConsumoResidenciaUser
              }
              _oeAssistido={_oeAssistido}
              _user={_user}
              ext={ext}
              isShowProximaAula={isMoreModule || isMoreObjeto}
              residenciaMedica={residenciaMedicas!}
              handleNextOe={handleNextOe}
            />

            {objetoEducacionalSelecionado?.attributes?.TipoObjeto?.data[0]
              .attributes?.titulo !== "Flashcard" && (
              <DescritivoObjeto
                objetoEducacionalSelecionado={
                  objetoEducacionalSelecionado || undefined
                }
                residenciaMedicas={residenciaMedicas}
              />
            )}
            {objetoEducacionalSelecionado &&
              objetoEducacionalSelecionado.attributes?.TipoObjeto?.data[0]
                .attributes?.titulo !== "Flashcard" && (
                <BarraFavoritar
                  user={_user}
                  objetoEducacionalSelecionado={objetoEducacionalSelecionado!}
                />
              )}
          </div>
          <div className="">
            <PainelResidencia
              residenciaMedicas={residenciaMedicas!}
              handleModuleToggle={handleModuleToggle}
              expandedModules={expandedModules}
              handleSetObjetoSelecionado={handleSetObjetoSelecionado}
              objetoEducacionalSelecionado={objetoEducacionalSelecionado}
              loading={loadingConsumoModulo || loadingConsumoResidenciaUser}
              _user={_user}
            />
          </div>
        </div>
      </Container>
      <Container>
        {(() => {
          let bannersCabecalho = banners?.filter(
            (a) => a.tipoPagina === TIPO_PAGINA.RESIDENCIA_MEDICA_MODULOS,
          );

          return (
            <>
              {bannersCabecalho && bannersCabecalho.length > 0 && (
                <div
                  data-banner
                  data-banner-cabecalho
                  className="mt-1 max-w-[946px]"
                >
                  <BannerSection items={bannersCabecalho} fixo={false} />
                </div>
              )}
            </>
          );
        })()}
      </Container>
      {objetoEducacionalSelecionado &&
        objetoEducacionalSelecionado?.attributes?.Autores?.data?.length! >
          0 && (
          <AboutAuthors
            oe={objetoEducacionalSelecionado!}
            Autores={
              objetoEducacionalSelecionado?.attributes?.Autores?.data as any
            }
          />
        )}
      <CursosPagosResidencia />
    </div>
  );
}
