import { useContext, useEffect, useRef, useState } from "react";
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../ui/carousel";
import { ObjetoEducacionalEntity } from "../../../generated/graphql";
import { cn } from "../../../lib/utils";
import * as Icon from "../../../assets/svgs";
import { twMerge } from "tailwind-merge";
import Container from "../Container";
import React from "react";
import useOeConsume from "../../../hooks/useOeConsume";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { UserContext } from "../../../context/UserContext";

export default function Flashcard({
  objetoEducacional,
}: {
  objetoEducacional: ObjetoEducacionalEntity;
}) {
  const [api, setApi] = useState<CarouselApi>();
  const [current, setCurrent] = useState(0);
  const [clicked, setClicked] = useState<number[]>([]);

  const [flashcards, setFlashcards] = useState<any[]>();

  const { user } = useContext(UserContext);
  const consume = useOeConsume();
  const handleTimeUpdateQuizRef = useRef<any>(null);

  useEffect(() => {
    const timeUpdateSubject = new Subject();

    // Atribui a função handleTimeUpdate ao useRef
    handleTimeUpdateQuizRef.current = (event: any, objetoEducacional: any) => {
      timeUpdateSubject.next({ event, objetoEducacional });
    };
    // Aplica o debounceTime ao subject
    const debouncedTimeUpdate = timeUpdateSubject.pipe(debounceTime(500));

    const oeAssistido =
        user?.attributes?.OesConsumidosParcialmente?.data.find(
          (item) =>
            !!item?.attributes?.Oes_Assistidos &&
            item.attributes.Oes_Assistidos.data.find(i => i.id === objetoEducacional.id) !== undefined
        );

    // Assina o subject debounced
    const subscription = debouncedTimeUpdate.subscribe(async (data: any) => {
      // Obtém o progresso atual do vídeo

      if ((oeAssistido?.attributes?.segundos || 0) > data.event.partialDuration) {
        return;
      }

      await consume.saveOeConsume({
        id: oeAssistido?.id?.toString() || undefined,
        partialDuration: data.event.partialDuration,
        totalDuration: data.event.totalDuration,
        oe: objetoEducacional.id,
        user: user?.id,
      });
    });

    if (oeAssistido && oeAssistido?.attributes?.segundos) {
      setCurrent(oeAssistido?.attributes?.segundos || 0);
    } else {
      setCurrent(1);
    }

    return () => {
      subscription.unsubscribe();
      timeUpdateSubject.complete();
    };

  }, [user, objetoEducacional])

  const updateTimeInProgress = (page: number, pageTotal: number) => {
    handleTimeUpdateQuizRef.current({
      partialDuration: page,
      totalDuration: pageTotal,
    })
  }

  useEffect(() => {
    if (objetoEducacional) {
      const flashcards = objetoEducacional.attributes?.flashcards?.data?.map(
        (flashcard) => {
          return {
            ...flashcard,
            color: generateRandomColor(),
          };
        }
      );

      setFlashcards(flashcards);
    }
  }, [objetoEducacional]);

  const [showTutorialModal, setShowTutorialModal] = useState(false);

  useEffect(() => {
    const tutorialShown = localStorage.getItem("flashcardTutorial");
    if (!tutorialShown) {
      setShowTutorialModal(true);
      localStorage.setItem("flashcardTutorial", "true");
    }
  }, []);

  function handleCloseModal() {
    setShowTutorialModal(false);
  }

  let nextColorIndexState = 0;

  function generateRandomColor() {
    const baseColors = [
      "bg-[#004F92]",
      "bg-[#107B95]",
      "bg-[#009688]",
      "bg-[#8CC152]",
      "bg-[#673FB4]",
      "bg-[#9A0B83]",
      "bg-[#E62565]",
      "bg-[#FC5830]",
      "bg-[#FD9727]",
    ];

    let nextColorIndex = nextColorIndexState || 0;

    const nextColor = baseColors[nextColorIndex];
    nextColorIndex = (nextColorIndex + 1) % baseColors.length;

    nextColorIndexState = nextColorIndex;

    return nextColor;
  }

  useEffect(() => {
    if (!api) {
      return;
    }

    //setCurrent(api.selectedScrollSnap() + 1);

    api.on("select", () => {
      setCurrent(api.selectedScrollSnap() + 1);
      updateTimeInProgress(api.selectedScrollSnap() + 1, flashcards?.length || 0);
    });
  }, [api]);

  function handleClick(index: number) {
    if (clicked.includes(index)) {
      setClicked(clicked.filter((item) => item !== index));
    } else {
      setClicked([...clicked, index]);
    }
  }

  return (
    <>
      <div className="flex gap-2 mb-6 overflow-x-scroll md:overflow-x-hidden overflow-y-hidden whitespace-nowrap pb-2 md:justify-center">
        {flashcards?.map((flashcard: any, index) => (
          <div
            key={index + 1}
            className={`min-w-[57px]  md:w-16 h-20 rounded-[2.99px] flex cursor-pointer  justify-center items-center flex-col gap-2 p-[8px] ${
              index === current - 1
                ? `shadow-md ${flashcard?.color}`
                : `${flashcard?.color} opacity-40`
            }`}
            onClick={() => api?.scrollTo(index)}
          >
            <div className="text-white text-center text-[4.98px] font-bold">
              Flashcards Einstein
            </div>
            <div className="w-[28px] h-[28px] flex justify-center items-center rounded-full bg-white">
              <span className="text-[15.27px] text-center  font-semibold">
                {index + 1}
              </span>
            </div>
          </div>
        ))}
      </div>
      <div className=" flex justify-center items-center flex-col">
        {showTutorialModal && (
          <div
            className={cn(
              "fixed  backdrop-blur-[4px] inset-0 z-[9999]  data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0"
            )}
            onClick={handleCloseModal}
          >
            <div
              className="w-[342px] h-[54px]  bg-[#F5F8FF] rounded-xl p-4 shadow-lg
            left-0 right-0 mx-auto top-0 bottom-0 my-auto absolute  text-sm font-medium text-[#004F92]
            justify-center items-center flex cursor-pointer"
            >
              <Icon.IconClickIcon className="mr-2" />
              Clique no card para ver a resposta
            </div>
          </div>
        )}

        <Carousel
          opts={{
            loop: true,
          }}
          setApi={setApi}
          initialSlide={current - 1}
          className="w-full max-w-xs"
        >
          <CarouselContent className="">
            {flashcards?.map((flashcard: any, index) => (
              <CarouselItem key={index} className="flex justify-center">
                <div className="group [perspective:1000px]">
                  <div
                    onClick={() => handleClick(index)}
                    className={twMerge(
                      "w-[267px] relative cursor-pointer p-4 h-[320px] items-center justify-center  flex rounded-xl",
                      flashcard?.color
                    )}
                    style={{
                      transformStyle: "preserve-3d",
                      transition: "all 0.5s",
                      transform: `${
                        index === clicked.filter((item) => item === index)[0]
                          ? "rotateY(180deg)"
                          : ""
                      }`,
                    }}
                  >
                    <span className="text-xxs font-normal text-white absolute left-4 top-4">
                      Flashcards <span className="font-bold">Einstein</span>
                    </span>
                    <div className="w-5 h-5 absolute right-4 top-4  bg-white rounded-full justify-center items-center flex">
                      <span className="text-[15.27px] text-center  font-semibold">
                        {index + 1}
                      </span>
                    </div>
                    <div className="text-white text-center text-sm font-normal">
                      {flashcard?.attributes?.pergunta}
                    </div>
                    <div
                      className={`absolute cursor-pointer ${flashcard?.color} flex items-center justify-center  p-4  inset-0 [transform:rotateY(180deg)] rounded-xl [backface-visibility:hidden]`}
                      style={{
                        transform: "rotateY(180deg)",
                        backfaceVisibility: "hidden",
                      }}
                    >
                      <div className="flex  absolute left-4 top-4 justify-between items-center">
                        <span className="text-xxs font-normal text-white">
                          Flashcards <span className="font-bold">Einstein</span>
                        </span>
                      </div>
                      <div className="w-5 h-5 absolute top-4 right-4 bg-white rounded-full justify-center items-center flex">
                        <span className="text-xs text-center  font-semibold">
                          {index + 1}
                        </span>
                      </div>
                      <div className="text-white absolute  p-4  text-center text-sm font-normal ">
                        {flashcard?.attributes?.resposta}
                      </div>
                    </div>
                  </div>
                </div>
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselPrevious data-testid="btn-previous-flashcard" className="mt-40 -ml-20 shadow-none" color="white" isShowButton />
          <CarouselNext data-testid="btn-next-flashcard"  className="mt-40 -mr-20 shadow-none" color="white" isShowButton/>
        </Carousel>
      </div>
    </>
  );
}
