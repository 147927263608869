import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { useGetUserByIdLazyQuery } from "../../../../generated/graphql";

import { TOKEN_SECRECT } from "../../../../shared/constants/auth-types";
import { KnowledgeArea } from "../../../../shared/interfaces/area.interface";
import { User } from "../../../../shared/interfaces/user.interface";
import { setLocalStorageValue } from "../../../../shared/services/localStorage.service";

import {
  FormBase as Form,
  Loader,
  ModalError,
  ModalService,
} from "../../../../shared/components";
import { ModalTermsV2 } from "../ModalTermsV2";
import { DadosPessoaisInterface } from "../DadosPessoaisV2";
import { DadosProfissionaisInterface } from "../DadosProfissionaisV2";
import { ModalRegisterUserSucceedV2 } from "../ModalRegisterUserSucceedV2";

import { useNovoUsuario } from "../../../../hooks/useNovoUsuario";
import useSelectPreference from "../../../../hooks/useSelectPreference";
import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../../../hooks/useGtm";

import "./styles.scss";
import { twMerge } from "tailwind-merge";
import { Button } from "../../../../shared/components/ui/button";
import { Checkbox } from "../../../../shared/components/ui/checkbox";
import { Label } from "../../../../shared/components/ui/label";
import { useFormik } from "formik";
import * as Yup from "yup";
import { cn } from "../../../../lib/utils";
import { AlertTriangle, CircleAlert, Loader2 } from "lucide-react";
import { fetchApi } from "../../../../shared/services/rest-dotnet.service";
import { ToastContainer, toast } from "react-toastify";

export interface StepHandlerState {
  areas: KnowledgeArea[];
  formData: User;
}

interface AreasInteresseInterface {
  acceptTerm: boolean;
  acceptSharePersonData: boolean;
  area_de_conhecimentos: number[];
  formStep: number;
}

export type NovoUsuarioInterface = { id: number } & DadosPessoaisInterface &
  DadosProfissionaisInterface &
  AreasInteresseInterface;

interface Props {
  origin?: string;
}

interface InterfaceFinal {
  acceptTerm: boolean;
  acceptSharePersonData: boolean;
  area_de_conhecimentos: number[];
}

export const AreasInteresseV2: React.FC<Props> = ({ origin }) => {
  const gtm = useGtm();
  const history = useHistory();
  const stateModalReducer = useSelector((state: any) => state.modalReducer);

  const { list: areas, selectPreference: _selectPreference } =
    useSelectPreference();

  const localUser = useNovoUsuario<NovoUsuarioInterface>();

  const [showAllErrorsMessages, setShowAllErrorsMessages] = useState(false);
  const [formData, setFormData] = useState<NovoUsuarioInterface | null>(null);
  const [jwt, setJwt] = useState<string>("");
  const [itemsChoosed, setItemsChoosed] = useState<string[]>([]);

  const formik = useFormik<InterfaceFinal>({
    initialValues: {
      acceptTerm: false,
      acceptSharePersonData: false,
      area_de_conhecimentos: [],
    },
    validationSchema: Yup.object().shape({
      acceptTerm: Yup.boolean()
        .default(false)
        .oneOf([true], "Por favor, aceite os termos de uso"),
      acceptSharePersonData: Yup.boolean().default(false),
      area_de_conhecimentos: Yup.array()
        .min(1, "Por favor, selecione pelo menos uma área de interesse.")
        .max(5, "Por favor, selecione até 5 áreas de interesse."),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      const data: NovoUsuarioInterface = { ...formData! };
      data.acceptTerm = values.acceptTerm;
      data.acceptSharePersonData = values.acceptSharePersonData;

      if (data.data_nascimento) {
        const parts = data.data_nascimento.split("/");
        const data_nascimento = `${parts[2]}-${parts[1]}-${parts[0]}`;
        data.data_nascimento = data_nascimento;
      }

      if (data.UF === null) {
        data.UF = "";
      }

      await fetchApi(
        "user/create-user",
        "POST",
        JSON.stringify({
          ...data,
          ...values,
          especialidade: data.especialidade ? data.especialidade : "",
          outra_profissao_filha: "",
          canal: "ADE",
          userAgent: navigator.userAgent,
          provider: "local",
          urlOrigin: window.location.href,
          createdAt: new Date(),
        }),
      )
        .then((resultado: any) => {
          if (resultado.status !== 200) {
            return resultado.json().then((response: any) => {
              return Promise.reject(response.message);
            });
          }

          return resultado.json();
        })
        .then((userCreated) => {
          setJwt(userCreated.jwt);

          ModalService.show({
            content: <ModalRegisterUserSucceedV2 origin={origin} />,
            closeOutsideModal: true,
            notFullScreenMobile: true,
            showCloseButton: true,
            onClose: () => {
              window.location.href = origin ? `/${origin}?logged=true` : "/";
            },
          });

          GetUserById();

          // GTM doc linha 41
          gtm.push(
            EDataLayerEventCategory.ADE_CADASTRO,
            EDataLayerEventAction.CLIQUE,
            {
              label: "cadastro-final",
            },
          );
        })
        .catch((error) => {
          toast.error(
            <div className="mx-2 flex flex-row items-center gap-3">
              <CircleAlert className="h-5 w-5" />
              <div className="flex flex-col">
                <div
                  style={{
                    fontWeight: 600,
                    fontSize: "12px",
                    color: "#FFFFFF",
                  }}
                >
                  Algo deu errado! Tente novamente
                </div>
              </div>
            </div>,
            {
              icon: false,
              closeButton: false,
              style: {
                backgroundColor: "#D64214",
                color: "white",
                borderRadius: "35px",
              },
            },
          );
        });
    },
  });

  const [GetUserById] = useGetUserByIdLazyQuery({
    onCompleted: (data) => {
      setLocalStorageValue(
        TOKEN_SECRECT,
        JSON.stringify({
          jwt,
          user: data.findMe,
        }),
      );

      localUser.clean();
    },
    onError: (error) => {
      console.error(error);
    },
  });

  useEffect(() => {
    if (!areas || !areas.length || !formData) {
      return;
    }

    if (!formData.area_de_conhecimentos) {
      formData.area_de_conhecimentos = [];
      setFormData({ ...formData });
    }

    if (formik.isSubmitting && formData.area_de_conhecimentos) {
      for (const area of areas) {
        if (
          formData.area_de_conhecimentos.some(
            (a) => area.id!.toString() === a.toString(),
          )
        ) {
          selectPreference(area.id!);
        }
      }
    }
  }, [areas, formData]);

  useEffect(() => {
    localUser.load().then((user) => {
      if (!!user) {
        setFormData(user);
        formik.setValues({
          acceptTerm: user.acceptTerm || false,
          acceptSharePersonData: user.acceptSharePersonData || false,
          area_de_conhecimentos: user.area_de_conhecimentos || [],
        });
        return;
      }

      history.push("/novo-usuario-v2/dados-pessoais");
    });
  }, [stateModalReducer]);

  const back = () => {
    localUser
      .save({
        ...formData,
        area_de_conhecimentos: formik.values.area_de_conhecimentos,
        formStep: 1,
      })
      .then(() => {
        gtm.push(
          EDataLayerEventCategory.ADE_CADASTRO,
          EDataLayerEventAction.CLIQUE,
          {
            label: "cadastro-abandono3",
          },
        );

        history.push("/novo-usuario-v2/dados-profissionais");
      });
  };

  // TODO: Utilizar tanto no cadastro quanto a edição (Meu perfil).
  const selectPreference = (id: string) => {
    const _listSelect = _selectPreference(id, formData!);

    if (!itemsChoosed.includes(id)) {
      const updatedItems = [...itemsChoosed, id];
      setItemsChoosed(updatedItems);
    }
    formik.setFieldValue("area_de_conhecimentos", _listSelect);

    const _: NovoUsuarioInterface = {
      ...formData!,
      area_de_conhecimentos: _listSelect as any[],
    };

    setFormData(_);
  };

  const handleOpenModalTerm = (e?: any) => {
    e?.preventDefault();
    ModalService.show({
      content: <ModalTermsV2 />,
      showCloseButton: true,
      closeOutsideModal: true,
    });
  };

  const handleSelectArea = (id: number) => {
    if (formik.values.area_de_conhecimentos.includes(id)) {
      formik.setFieldValue(
        "area_de_conhecimentos",
        formik.values.area_de_conhecimentos.filter((a) => a !== id),
      );
    } else {
      if (formik.values.area_de_conhecimentos.length < 5) {
        formik.setFieldValue("area_de_conhecimentos", [
          ...formik.values.area_de_conhecimentos,
          id,
        ]);
      }
    }
  };

  function sendEmail(emailAddress: string) {
    window.location.href = `mailto:${emailAddress}`;
  }

  async function handleBack() {
    await gtm.push(
      EDataLayerEventCategory.ADE_CADASTRO,
      EDataLayerEventAction.CLIQUE,
      {
        label: "cadastro-abandono3",
      },
    );
    back();
  }

  return (
    <>
      <Loader
        show={formik.isSubmitting}
        message="Aguarde enquanto o conteúdo é carregado..."
      />
      <ToastContainer />
      <form className="rounded-2xl !p-0 text-center md:border md:!p-10  tablet:!p-6  ">
        <div className="">
          <span className="hidden text-base font-bold text-[#004F92] md:text-xl">
            Áreas de Interesse
          </span>
          <p className="text-sm font-normal text-black-900 md:text-base ">
            Escolha até
            <span className="font-semibold"> 5 áreas de interesses</span> para
            personalizar sua página inicial.
          </p>
        </div>
        <div className="mt-4">
          <hr className="mb-8 hidden md:block" />
          <section className="flex max-h-80 flex-wrap justify-center gap-1 overflow-y-auto rounded-lg bg-white-200 md:flex md:h-auto md:max-h-96 md:gap-4">
            {areas.map((item: any) => (
              <div
                className={twMerge(
                  "flex size-24 cursor-pointer select-none flex-col items-center justify-center gap-2 rounded-lg border p-6 hover:bg-[#F5F8FF] md:h-36 md:w-36",
                  formik.values.area_de_conhecimentos.includes(item.id)
                    ? "border-[2px] border-[#004F92] bg-[#F5F8FF]"
                    : "",
                )}
                key={item.id}
                onClick={() => {
                  handleSelectArea(item.id);
                }}
              >
                <img
                  src={item.icone.url}
                  alt={item.titulo}
                  className="size-10 !fill-black-900 stroke-black-900 text-black-900 md:h-14 md:w-14"
                  loading="lazy"
                />
                <span className=" text-8 font-medium leading-normal text-[#000000] md:text-xs">
                  {item.titulo}
                </span>
              </div>
            ))}
          </section>
          <hr className="mt-8 hidden md:block" />
        </div>

        <div className="mt-4 flex flex-col gap-4">
          <div className="flex items-start space-x-2 text-start">
            {" "}
            <Checkbox
              className="mt-1 h-5 w-5"
              id="acceptTerm"
              name="acceptTerm"
              checked={formik.values.acceptTerm || false}
              onCheckedChange={(e) => {
                formik.setFieldValue("acceptTerm", e);
              }}
            />
            <Label
              htmlFor="terms"
              className="text-sm font-medium leading-5 peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              Declaro que li e concordo com os{" "}
              <span
                className="accept-term-click cursor-pointer text-sm !font-normal leading-5 text-blue-900 underline"
                onClick={handleOpenModalTerm}
              >
                termos de uso
              </span>{" "}
              da Academia Digital Einstein.
              <span className="!font-bold">* (CAMPO OBRIGATÓRIO)</span>
            </Label>
          </div>

          <div className="flex items-start space-x-2 text-start">
            <Checkbox
              id="acceptSharePersonData"
              className="mt-1 h-5 w-5"
              name="acceptSharePersonData"
              checked={formik.values.acceptSharePersonData || false}
              onCheckedChange={(e) => {
                formik.setFieldValue("acceptSharePersonData", e);
              }}
            />
            <Label
              htmlFor="acceptSharePersonData"
              className="text-sm font-normal leading-5 peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              Aceito fornecer meus dados pessoais a fim de receber informativos
              e contato do Ensino Einstein e seus parceiros, por meio de seus
              canais digitais de relacionamento
            </Label>
          </div>
        </div>

        {formik.errors && Object.keys(formik.errors).length > 0 && (
          <div
            className={twMerge(
              "flex w-full items-center justify-center pt-6 text-center",
            )}
          >
            <ul className=" flex flex-col items-center justify-center gap-2 text-center text-sm font-semibold text-red-600">
              {formik.errors.area_de_conhecimentos && (
                <li className="flex list-disc items-center gap-2 text-center">
                  <AlertTriangle className="h-4 w-4" />
                  {formik.errors.area_de_conhecimentos &&
                    showAllErrorsMessages &&
                    formik.errors.area_de_conhecimentos}
                </li>
              )}

              {formik.errors.acceptTerm && (
                <li className="flex list-disc items-center gap-2 text-center">
                  <AlertTriangle className="h-4 w-4" />
                  {formik.errors.acceptTerm &&
                    showAllErrorsMessages &&
                    formik.errors.acceptTerm}
                </li>
              )}
            </ul>
          </div>
        )}
        <div className="mt-6 flex w-full  flex-col-reverse items-center justify-center gap-6 md:flex-row tablet:!flex-row">
          <Button
            type="button"
            data-testid="btn-voltar"
            variant="outline"
            size="default"
            className="w-full md:!w-[160px] tablet:!w-[160px]"
            onClick={handleBack}
          >
            Voltar
          </Button>
          <Button
            type="button"
            data-testid="btn-cadastrar"
            disabled={formik.isSubmitting || formik.isValidating}
            className="w-full md:!w-[160px] tablet:!w-[160px]"
            onClick={() => {
              setShowAllErrorsMessages(true);
              formik.handleSubmit();
            }}
          >
            {formik.isSubmitting || formik.isValidating ? (
              <Loader2 className="h-5 w-5 animate-spin" />
            ) : (
              "Cadastrar"
            )}
          </Button>
        </div>
        <span className="mt-10 inline-block text-center text-sm font-medium leading-4 text-[#111316] md:text-start">
          Problemas no cadastro? <br />
          Envie email para{" "}
          <span
            className="cursor-pointer text-[#004F92] underline"
            onClick={() => sendEmail("academia.digital@einstein.br")}
          >
            academia.digital@einstein.br
          </span>
        </span>
      </form>
    </>
  );
};
