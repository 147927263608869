import {
  ArrowBackOutlined,
  Fullscreen,
  GetApp,
  KeyboardArrowDown,
  KeyboardArrowUp,
  PrintOutlined,
  Search,
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
} from "@material-ui/icons";
import { IconButton, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import {
  ComponentTextoSecaoPdf,
  UploadFileEntityResponse,
} from "../../../../generated/graphql";
import {
  LocalizationMap,
  Worker,
  LocalizationContext,
} from "@react-pdf-viewer/core";

// Core viewer
import { Viewer } from "@react-pdf-viewer/core";

import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import type {
  ToolbarSlot,
  TransformToolbarSlot,
} from "@react-pdf-viewer/toolbar";

import pt_PT from "@react-pdf-viewer/locales/lib/pt_PT.json";

// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import "./styles.scss";
import { useHistory } from "react-router";

import {
  getUserContext,
  getUserWithJwtContext,
} from "../../../../context/UserContext";
import { get } from "http";
import { getTokenFromCookie } from "../../../../shared/services/localStorage.service";

interface OePDFWatchProps {
  pdf: ComponentTextoSecaoPdf | UploadFileEntityResponse | undefined | any;
  idOe: string;
  residenciaMedica?: boolean;
  onChangePage?: (page: number, totalPages: number) => void;
}

const locale_de_PT = pt_PT as unknown as LocalizationMap;

const OePDFWatch: React.FC<OePDFWatchProps> = ({
  pdf,
  idOe,
  residenciaMedica = false,
  onChangePage
}) => {
  const history = useHistory();
  const toolbarPluginInstance = toolbarPlugin();
  const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;

  const locale = useContext(LocalizationContext);
  const localization = { ...locale, l10n: locale_de_PT };

  const { print } = toolbarPluginInstance.printPluginInstance;
  const { ShowSearchPopover } = toolbarPluginInstance.searchPluginInstance;
  const { Download } = toolbarPluginInstance.getFilePluginInstance;
  const { EnterFullScreen } = toolbarPluginInstance.fullScreenPluginInstance;
  const { GoToPreviousPage, GoToNextPage } =
    toolbarPluginInstance.pageNavigationPluginInstance;
  const { ZoomIn, ZoomOut } = toolbarPluginInstance.zoomPluginInstance;
  const user = getUserContext();
  const token = getTokenFromCookie();

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);


  const handlePageChange = (e: any) => {
    setCurrentPage(e.currentPage + 1); // Pages are zero-indexed
    onChangePage?.(e.currentPage + 1, totalPages);
  };

  const handleDocumentLoad = (e: any) => {
    setTotalPages(e.doc.numPages);
    if (e.doc.numPages === 1) {
      onChangePage?.(1, 1);
    }
  };

  const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
    ...slot,
    SwitchTheme: () => <></>,
    SwitchThemeMenuItem: () => <></>,
    Open: () => <></>,
    OpenMenuItem: () => <></>,
    ZoomIn: (props) => (
      <ZoomIn>
        {(props) => (
          <Tooltip title="Ampliar">
            <IconButton onClick={props.onClick}>
              <ZoomInIcon htmlColor="#FFFFFF" />
            </IconButton>
          </Tooltip>
        )}
      </ZoomIn>
    ),
    ZoomOut: (props) => (
      <ZoomOut>
        {(props) => (
          <Tooltip title="Afastar">
            <IconButton onClick={props.onClick}>
              <ZoomOutIcon htmlColor="#FFFFFF" />
            </IconButton>
          </Tooltip>
        )}
      </ZoomOut>
    ),
    GoToPreviousPage: (props) => (
      <GoToPreviousPage>
        {(props) => (
          <Tooltip title="Página anterior">
            <IconButton onClick={props.onClick}>
              <KeyboardArrowUp htmlColor="#FFFFFF" />
            </IconButton>
          </Tooltip>
        )}
      </GoToPreviousPage>
    ),
    GoToNextPage: (props) => (
      <GoToNextPage>
        {(props) => (
          <Tooltip title="Próxima Página">
            <IconButton onClick={props.onClick}>
              <KeyboardArrowDown htmlColor="#FFFFFF" />
            </IconButton>
          </Tooltip>
        )}
      </GoToNextPage>
    ),
    EnterFullScreen: (props) => (
      <EnterFullScreen>
        {(props) => (
          <Tooltip title="Tela Cheia">
            <IconButton onClick={props.onClick}>
              <Fullscreen htmlColor="#FFFFFF" />
            </IconButton>
          </Tooltip>
        )}
      </EnterFullScreen>
    ),
    ShowSearchPopover: (props) => (
      <ShowSearchPopover {...props}>
        {(props) => (
          <Tooltip title="Pesquisar">
            <IconButton onClick={props.onClick}>
              <Search htmlColor="#FFFFFF" />
            </IconButton>
          </Tooltip>
        )}
      </ShowSearchPopover>
    ),
    Download: (props) => (
      <Download>
        {(props) => (
          <Tooltip title="Download">
            <IconButton onClick={props.onClick}>
              <GetApp htmlColor="#FFFFFF" />
            </IconButton>
          </Tooltip>
        )}
      </Download>
    ),
    Print: (props) => {
      return (
        <Tooltip title="Imprimir">
          <IconButton onClick={print}>
            <PrintOutlined htmlColor="#FFFFFF" />
          </IconButton>
        </Tooltip>
      );
    },
  });

  useEffect(() => {
    console.log('Entrou')
  }, [])

  return (
    <LocalizationContext.Provider value={localization}>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.15.349/build/pdf.worker.min.js">
        <div
          className="rpv-core__viewer"
          style={{
            border: "1px solid rgba(0, 0, 0, 0.3)",
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div
            style={{
              alignItems: "center",
              backgroundColor: "#eeeeee",
              borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
              display: "flex",
              padding: "0.25rem",
            }}
          >
            <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
          </div>
          {!residenciaMedica && (
            <div
              onClick={() => {
                history.goBack();
              }}
              style={{
                display: "flex",
                position: "absolute",
                marginLeft: "6rem",
                top: 301,
              }}
            >
              <ArrowBackOutlined fontSize="large" />
            </div>
          )}
          <div
            style={{
              flex: 1,
              overflow: "hidden",
              paddingLeft: "calc((100% - 1246px) / 2)",
              paddingRight: "calc((100% - 1246px) / 2)",
            }}
          >
            {pdf && pdf.__typename === "ComponentTextoSecaoPdf" ? (
              <Viewer
                localization={pt_PT as unknown as LocalizationMap}
                fileUrl={`${
                  process.env.REACT_APP_ROOT_DOTNET
                }/api/objeto-educacional/get-pdf?oeId=${idOe}&token=${token}`}
                plugins={[
                  // Register plugins
                  toolbarPluginInstance as any,
                ]}
                onPageChange={handlePageChange}
                onDocumentLoad={handleDocumentLoad}
              />
            ) : (
              <Viewer
                localization={pt_PT as unknown as LocalizationMap}
                fileUrl={`${
                  process.env.REACT_APP_ROOT
                }/api/objeto-educacional/get-pdf/${encodeURIComponent(
                  (pdf as any).data?.attributes?.url!,
                )}`}
                plugins={[
                  // Register plugins
                  toolbarPluginInstance as any,
                ]}
                  onPageChange={handlePageChange}
                onDocumentLoad={handleDocumentLoad}
              />
            )}
          </div>
        </div>
      </Worker>
    </LocalizationContext.Provider>
  );
};

export default OePDFWatch;
