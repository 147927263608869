import Container from "../../../shared/components/Container";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselBullets,
  CarouselPrevious,
} from "../../../shared/components/ui/carousel";
import { fetchApi } from "../../../shared/services/rest-dotnet.service";
import { useContext, useEffect, useState } from "react";
import VerTudoIcon from "../../../assets/svgs/icons/ver-tudo.svg";
import { useHistory } from "react-router";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "../../../shared/components/ui/card";
import Rotulo from "../../../shared/components/rotulos";
import { Button } from "../../../shared/components/ui/button";
import {
  EDataLayerEventAction,
  EDataLayerEventCategory,
  useGtm,
} from "../../../hooks/useGtm";

async function getEventos() {
  const response = await fetchApi("evento/get-eventos", "GET").then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      return [];
    }
  });

  return response;
}

export default function EventosHome() {
  const [eventos, setEventos] = useState<any[]>([]);
  const history = useHistory();
  const gtm = useGtm();

  const loadAssistidos = async () => {
    setEventos(await getEventos());
  };

  useEffect(() => {
    loadAssistidos();
  }, []);

  return (
    <>
      {eventos && eventos.length > 0 && (
        <div className="flex w-full bg-[#F5F8FF] py-6">
          <Container className="flex flex-col gap-6 py-9 md:py-10">
            <div className="flex items-center  justify-between">
              <div className="">
                <div className="">
                  <div className="flex flex-col gap-3">
                    <h2 className="text-base font-semibold normal-case leading-18 text-brand-blue md:text-xxl md:leading-22 tablet:text-sm tablet:leading-[14px]">
                      Eventos Científicos{" "}
                    </h2>
                  </div>
                </div>
              </div>
              <span
                className="flex cursor-pointer items-center gap-2 self-center text-xxs  font-semibold leading-[10px] text-[#626466] md:text-sm md:leading-18"
                onClick={() => {
                  gtm.push(
                    EDataLayerEventCategory.ADE_EVENTOS_CIENTIFICOS,
                    EDataLayerEventAction.CLIQUE,
                    {
                      label: "area_vertudo_eventos_cientificos",
                    },
                  );
                  history.push("/eventos");
                }}
              >
                VER TUDO
                <img
                  src={VerTudoIcon}
                  alt=""
                  aria-hidden="true"
                  width={24}
                  height={24}
                  className="h-[14.45px] w-[14.45px] md:h-6 md:w-6"
                />
              </span>
            </div>

            {eventos && eventos.length > 0 ? (
              <Carousel
                opts={{
                  breakpoints: {
                    "(min-width: 768px)": { slidesToScroll: 4 },
                  },
                }}
                className="w-full"
              >
                <CarouselContent className="">
                  {eventos.slice(0, 12).map((item: any, index: number) => (
                    <CarouselItem
                      key={item.id}
                      className="rounded-xl md:basis-1/2 lg:basis-1/4"
                    >
                      <Card
                        key={index}
                        className="flex min-h-[300px] flex-col justify-between overflow-hidden border bg-white-200 md:min-h-[450px]"
                      >
                        <CardHeader className="relative mt-[-5px] flex h-[150px] p-0">
                          <img
                            src={item.imagemUrl}
                            alt={item.nome}
                            className="not-sr-only w-full bg-bottom bg-no-repeat object-cover"
                          />
                          <div className="absolute left-2 top-2">
                            <Rotulo
                              rotuloName="EVENTOS"
                              key={index}
                              id={item.idProduto}
                              isMetodoEnsino={true}
                            />
                          </div>
                        </CardHeader>
                        <CardContent className="mt-2 flex h-full flex-col gap-3">
                          <h3 className="line-clamp-3 !text-base font-semibold text-[#111316]">
                            {item.nome}
                          </h3>
                          <p className="text-sm font-medium leading-4 text-[#004F92]">
                            {item.dataEvento}
                          </p>
                          <p className=" text-sm font-normal leading-4">
                            {item.localEvento}
                          </p>
                          <p className=" text-sm font-normal leading-4">
                            {item.cidade === "São Paulo"
                              ? "São Paulo - SP"
                              : item.cidade}
                          </p>
                        </CardContent>
                        <CardFooter className="flex justify-center">
                          <Button
                            variant={"primary"}
                            data-testid="button-conheca"
                            className="w-[134px]"
                            onClick={() => {
                              window.open(item.eventoUrl, "_blank");
                              gtm.push(
                                EDataLayerEventCategory.ADE_EVENTOS_CIENTIFICOS,
                                EDataLayerEventAction.CLIQUE,
                                {
                                  label: `eventos_cientificos_${item.id}`,
                                },
                              );
                            }}
                          >
                            Conheça
                          </Button>
                        </CardFooter>
                      </Card>
                    </CarouselItem>
                  ))}
                </CarouselContent>

                <CarouselPrevious
                  data-testid="btn-previous-continue-assistindo"
                  height="h-[0px] md:h-[450px]"
                  className=" hidden md:block "
                  color="white"
                />

                <CarouselNext
                  data-testid="btn-next-continue-assistindo"
                  height="h-[0px] md:h-[450px]"
                  className=" hidden md:block"
                  color="white"
                />
                <CarouselBullets className="absolute left-0 right-0 mx-auto" />
              </Carousel>
            ) : (
              <Carousel
                opts={{
                  breakpoints: {
                    "(min-width: 768px)": { slidesToScroll: 4 },
                  },
                }}
                className="w-full"
              >
                <CarouselContent className="">
                  {Array.from({ length: 4 }).map((_, index) => (
                    <CarouselItem
                      key={index}
                      className="md:basis-1/2 lg:basis-1/3"
                    >
                      <div className="min-w-0 shrink-0 grow-0 basis-full overflow-hidden rounded-xl bg-slate-100 pl-4 md:basis-1/2 lg:basis-1/3">
                        <div className="flex h-[187.5px] w-full animate-[shimmer_2s_infinite] bg-gradient-to-r  from-transparent via-gray-400/20 to-transparent md:h-[227.66px]" />
                      </div>
                    </CarouselItem>
                  ))}
                </CarouselContent>
              </Carousel>
            )}
          </Container>
        </div>
      )}
    </>
  );
}
